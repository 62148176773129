import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import RegisterFace from "../components/pages/RegisterFace";
import FaceRecognition from "../components/pages/FaceRecognition";
import Attendance from "../components/pages/Attandance";
import RegisterFaceSingle from "../components/pages/RegisterFaceSIngle";
// import Login from "../components/pages/Login";
import VerifySuccess from "../components/pages/VerifySuccess";
import AttendSuccess from "../components/pages/AttendSuccess";
function AppRoutes() {
  return (
    // make navbar in router to make it appear in every page react js

    <Router>
      <Routes>
        {/* <Route path="/" exact element={<Login />} /> */}
        {/* <Route path="/" exact element={<Home />} /> */}
        {/* <Route path="/" exact element={<Attendance />} /> */}
        <Route path="/:nik?/:cust_id?/:tipe?" element={<Attendance />} />
        <Route path="/face_reg/:nik?" element={<RegisterFaceSingle />} />

        {/* <Route path="/:nik/:cust_id/:tipe"   component={<Attendance/>} /> */}
        <Route path="/register" element={<RegisterFace />} />
        <Route path="/absensi" element={<FaceRecognition />} />
        <Route path="/VerifySuccess" element={<VerifySuccess />} />
        <Route path="/attendSuccess" element={<AttendSuccess />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
