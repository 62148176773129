import * as faceapi from "face-api.js";
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useLocation } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RegisterFace() {
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [captureVideo, setCaptureVideo] = React.useState(false);
  const [statusWajah, setStatusWajah] = React.useState(
    "Memulai menjalankan Face Recognition ..."
  );
  const [severity, setSeverity] = React.useState("error");
  const [messageSubmit, setMessageSubmit] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [isFaceDetected, setIsFaceDetected] = React.useState(false);
  const [selectedPegawai, setSelectedPegawai] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [onSubmit, setOnSubmit] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [dataSetup, setDataSetup] = React.useState(null);
  const videoRef = React.useRef();
  const videoHeight = 480;
  const videoWidth = 375;
  const canvasRef = React.useRef();
  const { state } = useLocation();
  // const API_URL = "httsp://36.94.139.66:88/api/";

  const API_URL = "https://web.absenonline.net/api/";
  // const API_URL = "https://36.94.139.66:447/api/";
  const loadSetup = async () => {
    setIsLoading(true);
    const response = await axios.get(API_URL + "getSetup");
    setDataSetup(response.data.data);
    setIsLoading(false);
  };
  // please make function

  React.useEffect(() => {
    const loadModels = async () => {
      setIsLoading(true);

      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
      ]).then(setModelsLoaded(true), setIsLoading(false));
    };
    // setData(state.dataPegawai);
    console.log(videoRef);

    loadModels();
    loadSetup();
  }, []);

  const startVideo = () => {
    setCaptureVideo(true);

    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const handleVideoOnPlay = () => {
    setStatusWajah("Loading Face Recognition ...");
    setInterval(async () => {
      if (canvasRef && canvasRef.current && dataSetup) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };
        try {
          faceapi.matchDimensions(canvasRef.current, displaySize);

          const detections = await faceapi
            .detectAllFaces(
              videoRef.current,
              new faceapi.TinyFaceDetectorOptions()
            )
            .withFaceLandmarks()
            .withFaceExpressions()
            .withFaceDescriptors();

          // const resizedDetections = faceapi.resizeResults(
          //   detections,
          //   displaySize
          // );
          if (detections.length == 0) {
            setStatusWajah("Posisikan wajah Anda dekat di depan kamera");
          }
          if (detections.length > 0) {
            let landmark = detections[0].landmarks;
            const leftEye = landmark.getLeftEye();
            const rightEye = landmark.getRightEye();

            const distance = Math.sqrt(
              Math.pow(leftEye[0].x - rightEye[0].x, 2) +
                Math.pow(leftEye[0].y - rightEye[0].y, 2)
            );

            const threshold = dataSetup.jarak1;
            if (distance < threshold) {
              setStatusWajah("Dekatkan Wajah anda");
              setIsFaceDetected(false);
            } else {
              // recognizeFace(detections);
              // if success recognize face
              setStatusWajah("Ok");
              setIsFaceDetected(true);
            }
          }
          // canvasRef &&
          //   canvasRef.current &&
          //   canvasRef.current
          //     .getContext("2d")
          //     .clearRect(0, 0, videoWidth, videoHeight);
          // canvasRef &&
          //   canvasRef.current &&
          //   faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
          // canvasRef &&
          //   canvasRef.current &&
          //   faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
          // canvasRef &&
          //   canvasRef.current &&
          //   faceapi.draw.drawFaceExpressions(
          //     canvasRef.current,
          //     resizedDetections
          //   );
          // console.log(detections);

          // create correct head position illustration with large face
          // canvasRef &&
          //   canvasRef.current &&
          //   canvasRef.current.getContext("2d").beginPath();
          // canvasRef &&
          //   canvasRef.current &&
          //   canvasRef.current
          //     .getContext("2d")
          //     .rect(
          //       0.3 * videoWidth,
          //       0.3 * videoHeight,
          //       0.4 * videoWidth,
          //       0.4 * videoHeight
          //     );
          // canvasRef &&
          //   canvasRef.current &&
          //   canvasRef.current.getContext("2d").stroke();

          //   capture image if the face is detected and position is at the correct position

          //   end of capture image
          //   print size square face

          // print size square face
        } catch (err) {
          console.log(err);
        }
      }
    }, 500);
  };

  // const closeWebcam = () => {
  //   videoRef.current.pause();
  //   videoRef.current.srcObject.getTracks()[0].stop();
  //   setCaptureVideo(false);
  // };

  // const dataPegawai = data?.map((item) => {
  //   return {
  //     label: item.nik + " - " + item.nama,
  //     peg_id: item.peg_id,
  //     nik: item.nik,
  //   };
  // });

  const dataPegawai = [
    {
      label: "123",
      peg_id: "123",
      nik: "1234",
    },
  ];

  const ComboBox = () => {
    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={dataPegawai}
        sx={{ width: "100%" }}
        // on select pegawai open webcam
        // on
        onChange={(event, newValue) => {
          if (newValue) {
            startVideo();
          }
          setSelectedPegawai(newValue);
          console.log(newValue);
          // set auto complete component to disabled
        }}
        // clear value
        value={selectedPegawai}
        renderInput={(params) => (
          <TextField {...params} label="Pilih Pegawai" />
        )}
      />
    );
  };

  const CaptureImageHandle = async () => {
    setOnSubmit(true);
    // get image data from video element and post to server with descriptor
    const canvas = document.createElement("canvas");
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    // create image full size

    //
    const image = canvas.toDataURL("image/png");

    // create time stamp
    const date = new Date();
    const time = date.getTime();
    const timestamp = Math.floor(time / 1000).toString();

    const detection = await faceapi
      .detectSingleFace(videoRef.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceExpressions()
      .withFaceDescriptor();
    console.log(detection);
    const formData = new FormData();
    formData.append(
      "foto",
      dataURLtoBlob(image),
      timestamp + "_" + selectedPegawai.nik + ".png"
    );
    formData.append("peg_id", selectedPegawai.peg_id);
    formData.append("nik", selectedPegawai.nik);

    try {
      formData.append("descriptor", JSON.stringify(detection.descriptor));
      await axios.post(API_URL + "recognition", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleStatusSubmit("success");
      setStatusWajah("Wajah tidak terdeteksi");
      setIsFaceDetected(false);
      setOnSubmit(false);
      // reload page
      window.location.reload();
    } catch (err) {
      setOnSubmit(false);
      handleStatusSubmit("error");
      setStatusWajah("Wajah tidak terdeteksi");
      console.log(err);
    }
    setOnSubmit(false);
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleStatusSubmit = (result) => {
    if (result === "success") {
      setSeverity("success");
      setMessageSubmit("Data berhasil disimpan");
      setSelectedPegawai(null);
      setOpen(true);
      closeWebcam();
    } else {
      setSeverity("error");
      setMessageSubmit("Data gagal disimpan");
      setOpen(true);
    }
  };
  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={severity}>
          <AlertTitle>{severity}</AlertTitle>
          {messageSubmit}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography>Loading Data</Typography>
      </Backdrop>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 3,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <ComboBox />
          <button
            onClick={() => startVideo()}
            style={{
              cursor: "pointer",
              backgroundColor: "blue",
              color: "white",
              padding: "15px",
              fontSize: "25px",
              border: "none",
              borderRadius: "10px",
            }}
          >
            open
          </button>

          {captureVideo ? (
            modelsLoaded ? (
              <div>
                <Typography>{statusWajah}</Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <video
                    ref={videoRef}
                    height={videoHeight}
                    width={videoWidth}
                    onPlay={handleVideoOnPlay}
                    style={{
                      borderRadius: "10px",
                    }}
                    // dont make mirror
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/frame.png"}
                    style={{
                      position: "absolute",
                      width: { videoWidth },
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                  <canvas ref={canvasRef} style={{ position: "absolute" }} />
                </div>
              </div>
            ) : (
              <div>loading...</div>
            )
          ) : (
            <></>
          )}

          <div style={{ textAlign: "center", padding: "10px" }}>
            {isFaceDetected && selectedPegawai !== null ? (
              <button
                onClick={CaptureImageHandle}
                style={{
                  cursor: "pointer",
                  backgroundColor: "blue",
                  color: "white",
                  padding: "15px",
                  fontSize: "25px",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                {onSubmit ? <CircularProgress /> : <>Capture Image</>}
              </button>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </Box>
    </div>
  );
}

export default RegisterFace;
