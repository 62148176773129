import * as faceapi from "face-api.js";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function RegisterFaceSingle() {
  const [modelsLoaded, setModelsLoaded] = useState(false);
  const [captureVideo, setCaptureVideo] = useState(false);
  const [statusWajah, setStatusWajah] = useState(
    "Memulai menjalankan Face Recognition ..."
  );
  const navigate = useNavigate();
  const [btnCam, setBtnCam] = useState(true);
  const [severity, setSeverity] = useState("error");
  const [messageSubmit, setMessageSubmit] = useState("");
  const [open, setOpen] = useState(false);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [selectedPegawai, setSelectedPegawai] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);
  const videoRef = useRef();
  const videoHeight = 480;
  const videoWidth = 375;
  const canvasRef = useRef();
  const { nik } = useParams();
  const [pegawai, setPegawai] = useState(null);

  const API_URL = "https://web.absenonline.net/api/";
  const API_URL_CRESS = "https://cress.pastelsolution.com/api/";

  const loadPegawai = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(API_URL_CRESS + "user" + "/" + nik);
      setPegawai(response.data.data);
      setSelectedPegawai(response.data.data);
      // startVideo();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const loadModels = async () => {
      setIsLoading(true);

      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
          faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
          faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
          faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
          faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
        ]).then(setModelsLoaded(true), setCaptureVideo(true));
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadModels();
    loadPegawai();
  }, []);
  useEffect(() => {
    // startVideo();
  }, [captureVideo, modelsLoaded]);
  useEffect(() => {
    console.log(captureVideo, "caprured");
    console.log(modelsLoaded, "model");
  }, [captureVideo, modelsLoaded]);

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        console.log(videoRef.current);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        }
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const handleVideoOnPlay = async () => {
    setStatusWajah("Loading Face Recognition ...");
    setInterval(async () => {
      if (canvasRef && canvasRef.current) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };
        try {
          faceapi.matchDimensions(canvasRef.current, displaySize);

          const detections = await faceapi
            .detectAllFaces(
              videoRef.current,
              new faceapi.TinyFaceDetectorOptions()
            )
            .withFaceLandmarks()
            .withFaceExpressions()
            .withFaceDescriptors();

          // const resizedDetections = faceapi.resizeResults(
          //   detections,
          //   displaySize
          // );
          if (detections.length == 0) {
            setStatusWajah("Posisikan wajah Anda dekat di depan kamera");
          }
          if (detections.length > 0) {
            let landmark = detections[0].landmarks;
            const leftEye = landmark.getLeftEye();
            const rightEye = landmark.getRightEye();

            const distance = Math.sqrt(
              Math.pow(leftEye[0].x - rightEye[0].x, 2) +
                Math.pow(leftEye[0].y - rightEye[0].y, 2)
            );

            const threshold = 40;
            if (distance < threshold) {
              setStatusWajah("Dekatkan Wajah anda");
              setIsFaceDetected(false);
            } else {
              // recognizeFace(detections);
              // if success recognize face
              setStatusWajah("Ok");
              setIsFaceDetected(true);
            }
          }
        } catch (err) {
          console.log(err);
        }
      }
    }, 500);
  };

  const CaptureImageHandle = async () => {
    setOnSubmit(true);
    const canvas = document.createElement("canvas");
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

    const image = canvas.toDataURL("image/png");

    const date = new Date();
    const time = date.getTime();
    const timestamp = Math.floor(time / 1000).toString();

    const detection = await faceapi
      .detectSingleFace(videoRef.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceExpressions()
      .withFaceDescriptor();

    const formData = new FormData();
    formData.append(
      "foto",
      dataURLtoBlob(image),
      timestamp + "_" + selectedPegawai.nik + ".png"
    );
    formData.append("peg_id", selectedPegawai.peg_id);
    formData.append("nik", selectedPegawai.nik);

    try {
      formData.append("face_id", JSON.stringify(detection.descriptor));
      await axios.post(API_URL_CRESS + "facereg", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      handleStatusSubmit("success");
      setStatusWajah("Wajah tidak terdeteksi");
      setIsFaceDetected(false);
      navigate("/VerifySuccess");
    } catch (err) {
      setOnSubmit(false);
      handleStatusSubmit("error");
      setStatusWajah("Wajah tidak terdeteksi");
      console.log(err);
    } finally {
      setOnSubmit(false);
    }
  };

  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (let i = 0; i < n; i++) {
      u8arr[i] = bstr.charCodeAt(i);
    }
    return new Blob([u8arr], { type: mime });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleStatusSubmit = (result) => {
    if (result === "success") {
      setSeverity("success");
      setMessageSubmit("Data berhasil disimpan");
      setSelectedPegawai(null);
      setOpen(true);
      closeWebcam();
    } else {
      setSeverity("error");
      setMessageSubmit("Data gagal disimpan");
      setOpen(true);
    }
  };

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
  };
  const openWebcam = () => {
    setBtnCam(false);
    startVideo();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity={severity}>
          <AlertTitle>{severity}</AlertTitle>
          <span id="'verif-stat">{messageSubmit}</span>
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography>Loading Data</Typography>
      </Backdrop>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 3,
          pb: 6,
        }}
      >
        <div style={{ textAlign: "center", padding: "10px" }}>
          <Typography>{statusWajah}</Typography>
        </div>
        <Container maxWidth="sm">
          {captureVideo ? (
            modelsLoaded ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <video
                    ref={videoRef}
                    height={videoHeight}
                    width={videoWidth}
                    onPlay={handleVideoOnPlay}
                    style={{
                      borderRadius: "10px",
                    }}
                    // dont make mirror
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/frame.png"}
                    style={{
                      position: "absolute",
                      width: { videoWidth },
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                  <canvas ref={canvasRef} style={{ position: "absolute" }} />
                </div>
              </div>
            ) : (
              <div>loading...</div>
            )
          ) : (
            <></>
          )}

          <div style={{ textAlign: "center", padding: "10px" }}>
            {btnCam && (
              <button
                onClick={openWebcam}
                style={{
                  cursor: "pointer",
                  backgroundColor: "blue",
                  color: "white",
                  padding: "15px",
                  fontSize: "15px",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                Open Camera
              </button>
            )}

            {isFaceDetected && selectedPegawai !== null ? (
              <button
                onClick={CaptureImageHandle}
                style={{
                  cursor: "pointer",
                  backgroundColor: "blue",
                  color: "white",
                  padding: "15px",
                  fontSize: "25px",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                {onSubmit ? <CircularProgress /> : <>Capture Image</>}
              </button>
            ) : (
              <></>
            )}
          </div>
        </Container>
      </Box>
    </div>
  );
}

export default RegisterFaceSingle;
