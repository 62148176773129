import AppRoutes from './routes/AppRoutes';
// import LandingPage from './components/pages/LandingPage';
// import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

function App() {
  return (

    <AppRoutes />
  );
}


export default App;
