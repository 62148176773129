import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function AttendSuccess() {
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
      maxWidth="sm"
    >
      <CheckCircleIcon
        style={{
          fontSize: 80,
          // color: theme.palette.success.main,
          color: "green",
          marginBottom: 2,
        }}
      />
      <Typography variant="h5">Absensi Berhasil !</Typography>
    </Container>
  );
}

export default AttendSuccess;
