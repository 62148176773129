import * as faceapi from "face-api.js";
import React, { useState } from "react";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

function Attendance() {
  const [modelsLoaded, setModelsLoaded] = React.useState(false);
  const [captureVideo, setCaptureVideo] = React.useState(false);
  const [btnCam, setBtnCam] = useState(true);
  const [isLoading, setIsLoading] = React.useState(true);
  const [faceList, setFaceList] = useState(null);
  let faceListRaw = [];
  const navigate = useNavigate();

  const videoRef = React.useRef();
  const videoHeight = 480;
  const videoWidth = 375;
  const canvasRef = React.useRef();
  const [userName, setUserName] = useState("Sedang menarik data ...");
  // const API_URL = "https://103.180.164.252:447/api/";
  const API_URL = "https://web.absenonline.net/api/";
  // const API_URL_CRESS = "https://absencressida.site:88/api/";
  const API_URL_CRESS = "https://cress.pastelsolution.com/api/";
  const [dataSetup, setDataSetup] = React.useState(null);
  const [usrFaceData, setUsrFaceData] = useState(null);
  const { nik, cust_id, tipe } = useParams();

  // const API_URL = "https://36.94.139.66:447/api/";
  // const API_URL = "http://127.0.0.1:8000/api/";

  const [isWebcamOn, setIsWebcamOn] = useState(true);
  let counter = 0;
  let myInterval;

  const loadFacePegawai = async () => {
    setIsLoading(true);
    const response = await axios.get(API_URL_CRESS + "user" + "/" + nik);
    // console.log(response.data.data);
    setUsrFaceData(response.data.data.datape);
    faceListRaw.push(response.data.data.data_pegawai);
    let face_data = await labledFaceFromData(faceListRaw);
    console.log(face_data);
    setFaceList(face_data);
    setIsLoading(false);
  };

  React.useEffect(() => {
    // console.log(state);
    const loadModels = async () => {
      setIsLoading(true);

      const MODEL_URL = process.env.PUBLIC_URL + "/models";
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceapi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
        faceapi.nets.ssdMobilenetv1.loadFromUri(MODEL_URL),
      ]).then(setModelsLoaded(true), setIsLoading(false), startVideo());
      // cache loaded models to local storage
    };

    // loadLocation()
    // loadSetup();
    loadFacePegawai();
    loadModels();
  }, []);

  const startVideo = () => {
    setCaptureVideo(true);
    navigator.mediaDevices
      .getUserMedia({ video: { width: 300 } })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.play();
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  const handleVideoOnPlay = () => {
    setUserName("Loading Data silahkan tunggu ...");
    myInterval = setInterval(async () => {
      if (canvasRef && canvasRef.current && faceList) {
        canvasRef.current.innerHTML = faceapi.createCanvasFromMedia(
          videoRef.current
        );
        const displaySize = {
          width: videoWidth,
          height: videoHeight,
        };

        try {
          faceapi.matchDimensions(canvasRef.current, displaySize);

          const detections = await faceapi
            .detectAllFaces(
              videoRef.current,
              new faceapi.TinyFaceDetectorOptions()
            )
            .withFaceLandmarks()
            .withFaceExpressions()
            .withFaceDescriptors();

          if (detections.length == 0) {
            setUserName("Sedang mendeteksi wajah ...");
          }
          if (detections.length > 0) {
            let landmark = detections[0].landmarks;
            const leftEye = landmark.getLeftEye();
            const rightEye = landmark.getRightEye();

            const distance = Math.sqrt(
              Math.pow(leftEye[0].x - rightEye[0].x, 2) +
                Math.pow(leftEye[0].y - rightEye[0].y, 2)
            );

            const threshold = 30;

            if (distance < threshold) {
              setUserName(
                "Posisikan wajah anda dekat di depan kamera dan di tengah"
              );
            } else {
              console.log("okkkk");
              recognizeFace(detections);
              // if success recognize face
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }, 1000);
  };

  const closeWebcam = () => {
    videoRef.current.pause();
    videoRef.current.srcObject.getTracks()[0].stop();
    setCaptureVideo(false);
    clearInterval();
  };

  const labledFaceFromData = async (data) => {
    // console.log(data);
    const labeledFaceDescriptors = await Promise.all(
      data.map(async (item) => {
        const name = item.nama + "-" + item.nik;
        const face_id = JSON.parse(item.face_id);
        const length = Object.keys(face_id).length;
        const face_data = new Float32Array(length);

        for (let i = 0; i < length; i++) {
          face_data[i] = face_id[i];
        }

        return new faceapi.LabeledFaceDescriptors(name, [face_data]);
      })
    );
    return labeledFaceDescriptors;
  };
  const recognizeFace = async (detections) => {
    console.log(faceList);
    if (faceList) {
      const faceMatcher = new faceapi.FaceMatcher(faceList, 0.6);
      console.log(faceList);
      detections.forEach((detection) => {
        const bestMatch = faceMatcher.findBestMatch(detection.descriptor);
        console.log(bestMatch);
        // setUserName(bestMatch._label);
        // if 60% match

        if (bestMatch._distance < 0.6) {
          clearInterval(myInterval);
          setUserName(bestMatch._label);
          attendance(
            bestMatch._label.split("-")[1],
            bestMatch._label.split("-")[0],
            bestMatch
          );
        } else {
          setUserName("Wajah tidak dikenali / tidak terdaftar");
        }
      });
    }
  };
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  };
  const attendance = async (nik, nama, best_match) => {
    const canvas = document.createElement("canvas");
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    canvas
      .getContext("2d")
      .drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);
    // create image full size
    //
    // date string for image name
    const date = new Date();
    // timestamp
    const time = date.getTime();
    const timestamp = Math.floor(time / 1000).toString();
    let name = nama;
    const image = canvas.toDataURL("image/png");
    const formData = new FormData();
    formData.append("nik", nik);
    formData.append("cust_id", cust_id);
    formData.append(
      "foto",
      dataURLtoBlob(image),
      timestamp + "_" + nik + ".png"
    );
    formData.append("face_id", best_match);
    formData.append("shift", 1);
    formData.append("status", tipe);

    try {
      const response = await axios.post(API_URL_CRESS + "attend", formData);
      counter++;

      closeWebcam();

      navigate("/attendSuccess");

      // MySwal.fire({
      //   icon: "success",
      //   title: "Berhasil Absen",
      //   // text: nameAbsen,
      //   // html with custom html big size green color
      //   html: `<h1 id="success-absen">${name}</h1>`,

      //   showConfirmButton: false,
      //   timer: 5000,
      // });
      // delete image

      // wait 3 second and startvideo
      // handleOnSuccessAttendance();
    } catch (error) {
      setUserName("re");
      console.log(error);
    }
  };
  const handleOnSuccessAttendance = () => {
    // wait 3 second and startvideo
    console.log(counter);
    if (counter == 3) {
      console.log("reload");
      counter = 0;
      window.location.reload();
    }

    setTimeout(() => {
      // reaload page
      // window.location.reload();
      startVideo();
    }, 4000);
  };
  React.useEffect(() => {
    if (isWebcamOn) {
      // turn on webcam code
    } else {
      closeWebcam();
    }
  }, [isWebcamOn]);
  const openWebcam = () => {
    setBtnCam(false);
    startVideo();
  };
  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
        <Typography variant="h6" component="div">
          Loading
        </Typography>
      </Backdrop>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 3,
          pb: 6,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h6"
            variant="h6"
            align="center"
            color="text.info"
          ></Typography>
          {captureVideo ? (
            modelsLoaded ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <video
                    ref={videoRef}
                    height={videoHeight}
                    width={videoWidth}
                    onPlay={handleVideoOnPlay}
                    style={{ borderRadius: "10px" }}
                  />
                  <img
                    src={process.env.PUBLIC_URL + "/frame.png"}
                    style={{
                      position: "absolute",
                      width: { videoWidth },
                      height: "auto",
                      borderRadius: "10px",
                    }}
                  />
                  <canvas ref={canvasRef} style={{ position: "absolute" }} />
                </div>
                <div style={{ textAlign: "center" }}>
                  <h1
                    style={{
                      textAlign: "center",
                      color: "green",
                    }}
                  >
                    {userName === "Loading Data silahkan tunggu ..." ? (
                      <CircularProgress color="inherit" />
                    ) : (
                      ""
                    )}{" "}
                    {userName}
                  </h1>
                </div>
              </div>
            ) : (
              <div>loading...</div>
            )
          ) : (
            <></>
          )}
          <div style={{ textAlign: "center", padding: "10px" }}>
            {btnCam && (
              <button
                onClick={openWebcam}
                style={{
                  cursor: "pointer",
                  backgroundColor: "blue",
                  color: "white",
                  padding: "15px",
                  fontSize: "15px",
                  border: "none",
                  borderRadius: "10px",
                }}
              >
                Open Camera
              </button>
            )}
          </div>
        </Container>
      </Box>
    </div>
  );
}

export default Attendance;
